import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import RegularNavbar from '../../Navbars/RegularNavbar'
import SideNav from '../../Navbars/SideNav'
import Frame1 from './iconos/Frame 1.png'
import Frame2 from './iconos/Frame 2.png'
import Frame3 from './iconos/Frame 3.png'
import Frame4 from './iconos/Frame 4.png'
import Frame5 from './iconos/Frame 5.png'
import Frame6 from './iconos/Frame 6.png'
import Frame7 from './iconos/Frame 7.png'
import Frame8 from './iconos/Frame 8.png'
import bgserv from '../../multimedia/imagenes/servicios-tecnologicos/fondo-serv-tec.jpg'
import videoServiciosTecnologicos from '../../multimedia/videos/servicios-tecnologicos/bg-tecnologico.mp4'
import transicionEscalera from '../../Transiciones/transicionEscalera'
import transicionBottomTop from '../../Transiciones/transicionBottomTop'

const ServiciosTecnologicos = () => {
    const isMountedRef = useRef(false);
    const servicios = [
        {
            "imagen": Frame1,
            "titulo": "Plataforma para Sistemas de Salud",
            "path": "/servicios/servicios-tecnologicos/plataforma-para-sistemas-de-salud"
        },
        {
            "imagen": Frame2,
            "titulo": "Telemedicina de Urgencias",
            "path": "/servicios/servicios-tecnologicos/telemedicina-de-urgencias"
        },
        {
            "imagen": Frame3,
            "titulo": "Firma Digital",
            "path": "/servicios/servicios-tecnologicos/firma-digital"
        },
        {
            "imagen": Frame4,
            "titulo": "Software de Diagnóstico por Imágenes",
            "path": "/servicios/servicios-tecnologicos/software-de-diagnostico-por-imagenes"
        },
        {
            "imagen": Frame5,
            "titulo": "Consultoría Tecnológica",
            "path": "/servicios/servicios-tecnologicos/consultoria-tecnologica"
        },
        {
            "imagen": Frame6,
            "titulo": "Interoperabilidad X Road",
            "path": "/servicios/servicios-tecnologicos/interoperabilidad-x-road"
        },
        {
            "imagen": Frame7,
            "titulo": "Salud en Ciudades Inteligentes",
            "path": "/servicios/servicios-tecnologicos/salud-en-ciudades-inteligentes"
        },
        {
            "imagen": Frame8,
            "titulo": "Sistemas de ERP O Sociales",
            "path": "/servicios/servicios-tecnologicos/sistemas-de-erp-o-sociales"
        }
    ]
    

    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])

    useEffect(()=>{
    },[isMountedRef])
    
    return (
        <main className='container-fluid servicios-tecnologicos-section' id='main-element'>
            <SideNav />
            {/* <video className='background-effect' autoPlay muted loop playsInline>
                <source src={videoServiciosTecnologicos} type='video/mp4' />
            </video> */}
            <div className="mb-5">
                <div className="intro d-flex justify-content-center">
                    <video className='background-effect-v2' autoPlay muted loop playsInline>
                        <source src={videoServiciosTecnologicos} type='video/mp4' />
                    </video>
                    <div className="intro-text d-flex flex-column align-items-center z-10 my-auto">
                        <div>
                            <h1 className='poppins text-light d-flex flex-column'><span>SERVICIOS</span><span>TECNOLÓGICOS</span><span>MÉDICOS</span></h1>
                            <p className='text-light'>En el ámbito de la salud, la tecnología juega un papel fundamental en la mejora de la atención médica y la optimización de los procesos clínicos</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 m-0">
                    <div className="blue-section col-12 p-0 m-0">
                        <div className="container-xxl serv-container d-flex px-0">
                            {servicios.map((servicio,index)=>{
                                return(
                                    <div className='col-md-3 col-sm-4 col-5 d-flex justify-content-center'>
                                        <Link to={servicio.path}>
                                            <div className='d-flex serv flex-column flex-wrap mb-4' key={index}>
                                                <img className='mb-3' src={servicio.imagen} alt={servicio.titulo} />
                                                <h3>{servicio.titulo}</h3>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-12 serv-text-container p-0 m-0">
                        <img src={bgserv} className="w-100 h-100 object-fit-cover" alt="Fondo servicios tecnologicos" />
                        <div className="container-xxl">
                            <div className="text-paragraphs col-md-7 col-11 d-flex flex-column px-5">
                                <p className="fs-5 text-light p-0 m-0">En FISP, creemos firmemente que la implementación de tecnologías de salud es un paso esencial hacia un sistema de anteción médica más sostenible y centrado en el paciente.</p>
                                <p className="fs-5 text-light p-0 m-0">Hemos asumido el compromiso de ayudar a las administraciones a avanzar hacia un futuro de atención médica más eficiente, transparente y centrada en el paciente, aprovechando las últimas innovaciones tecnológicas.</p>
                                <p className="fs-5 text-light p-0 m-0">Con una sólida trayectoria en la implementación exitosa de soluciones de tecnología de la salud en todo el mundo. Nuestra experiencia abarca desde la gestión de registros médicos electrónicos hasta la telemedicina y la optimización de procesos hospitalarios.</p>
                                <p className="fs-5 text-light p-0 m-0">Conocemos los desafios únicos que enfrentan las instituciones gubernamentales de salud y estamos listos para abordarlos de manera eficas</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <RegularNavbar />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(ServiciosTecnologicos) : transicionBottomTop(ServiciosTecnologicos);
