import { useParams } from 'react-router-dom';
import ServiciosTecnologicos from './ServiciosTecnologicos/ServiciosTecnologicos';
import ProduccionMarketing from './ProduccionMarketing/ProduccionMarketing';
import ServiciosMedicos from './ServiciosMedicos/ServiciosMedicos';
import PaginaError from '../PaginaError';
import WellnessCorporativo from './WellnessCorporativo/WellnessCorporativo';
import ServiciosTecnologicosV2 from './ServiciosTecnologicos/ServiciosTecnologicosV2';

const ServicioRedirect = () => {
    const { service } = useParams();
    let serviceComponent;
    switch (service) {
        // case 'servicios-tecnologicos-v2':
        //     serviceComponent = <ServiciosTecnologicosV2 />
        //     break;
        case 'servicios-tecnologicos':
            serviceComponent = <ServiciosTecnologicos />
            break;
        case 'programa-de-wellness-corporativo':
            serviceComponent = <WellnessCorporativo />
            break;
        case 'produccion-marketing':
            serviceComponent = <ProduccionMarketing />
            break;
        case 'servicios-medicos':
            serviceComponent = <ServiciosMedicos />
            break;
        default:
            serviceComponent = <PaginaError />
            break;
    }

    return serviceComponent
};

export default ServicioRedirect;